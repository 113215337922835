var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('main', {
    staticClass: "main page_dashboard"
  }, [_c('article', {
    staticClass: "school-article"
  }, [_c('section', {
    staticClass: "school_data"
  }, [_c('div', {
    staticClass: "school_data__item"
  }, [_c('p', {
    staticClass: "name"
  }, [_vm._v(_vm._s(_setup.me.government_name) + " 全校欠席状況")]), _c('p', [_c('span', {
    staticClass: "date"
  }, [_vm._v(_vm._s(_vm.currentDate))]), _c('span', {
    staticClass: "update"
  }, [_vm._v(" " + _vm._s(_vm.updatedAt) + "更新")])])]), _c('div', {
    staticClass: "school_data__item"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.selectedDay,
      expression: "selectedDay"
    }],
    attrs: {
      "type": "date"
    },
    domProps: {
      "value": _vm.selectedDay
    },
    on: {
      "change": _vm.changeDate,
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.selectedDay = $event.target.value;
      }
    }
  })])]), _c('section', {
    staticClass: "section_base row-short"
  }, [_c('div', {
    staticClass: "section_base__item status_box"
  }, [_c('div', {
    staticClass: "result"
  }, [_c('p', {
    staticClass: "result__heading"
  }, [_vm._v("全生徒数")]), _c('p', {
    staticClass: "result__number"
  }, [_c('span', {
    staticClass: "num"
  }, [_vm._v(_vm._s(_vm._f("addComma")(_vm.totalStudents)))]), _c('span', {
    staticClass: "text"
  }, [_vm._v("名")])])]), _c('div', {
    staticClass: "result"
  }, [_c('p', {
    staticClass: "result__heading"
  }, [_vm._v("欠席")]), _c('p', {
    staticClass: "result__number"
  }, [_c('span', {
    staticClass: "num"
  }, [_vm._v(_vm._s(_vm._f("addComma")(_vm.totalAbsence)))]), _c('span', {
    staticClass: "text"
  }, [_vm._v("名(" + _vm._s((_vm.totalAbsence * 100 / _vm.totalStudents).toFixed(1)) + "%)")])])]), _c('div', {
    staticClass: "list"
  }, [_c('dl', {
    staticClass: "list__dl"
  }, [_c('div', {
    staticClass: "list__item"
  }, [_c('dt', {
    staticClass: "list__item__heading"
  }, [_vm._v("内)感染症")]), _c('dd', {
    staticClass: "list__item__text"
  }, [_c('span', {
    staticClass: "num"
  }, [_vm._v(_vm._s(_vm._f("addComma")(_vm.totalInfection)))]), _c('span', {
    staticClass: "text"
  }, [_vm._v("名(" + _vm._s((_vm.totalInfection * 100 / _vm.totalStudents).toFixed(1)) + "%)")])])])])]), _c(_setup.Inc137)], 1), _c('div', {
    staticClass: "section_base__item condition_box"
  }, [_c(_setup.PieChart, {
    attrs: {
      "pie-chart-data": _vm.pieChartData,
      "height": 230
    }
  }), _c(_setup.Inc137)], 1), _c('div', {
    staticClass: "section_base__item map_box"
  }, [_c(_setup.PointsMap, {
    attrs: {
      "height": "330px",
      "is-show-menu": false,
      "selected-day": _vm.selectedDay,
      "current-obj": _vm.currentObj
    }
  }), _c(_setup.Inc137, {
    attrs: {
      "bottom-val": 40
    }
  })], 1), _c('div', {
    staticClass: "section_base__item graph_box"
  }, [_c('h3', {
    style: {
      textAlign: 'center'
    }
  }, [_vm._v("欠席者総数の変化")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.barMonth,
      expression: "barMonth"
    }],
    attrs: {
      "id": "barMonth"
    },
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.barMonth = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }, _vm.barMonthChange]
    }
  }, [_c('option', {
    attrs: {
      "value": "current"
    }
  }, [_vm._v("直近31日")]), _vm._l(_vm.monthList, function (month, index) {
    return _c('option', {
      key: index,
      domProps: {
        "value": month.period
      }
    }, [_vm._v(" " + _vm._s(month.month) + "月 ")]);
  })], 2), _c(_setup.Bar, {
    attrs: {
      "bar-data": _vm.barData,
      "active-grade": "all",
      "width": 280,
      "height": 270,
      "child-id": "barChart1"
    }
  }), _c(_setup.Inc137)], 1)])]), _c('article', {
    staticClass: "school-article"
  }, [_c('section', {
    staticClass: "school_data"
  }, [_c('div', {
    staticClass: "school_data__item school"
  }, [_c('p', {
    staticClass: "name"
  }, [_vm._v(_vm._s(_vm.targetSchool.name))]), _c('p', [_c('span', {
    staticClass: "date"
  }, [_vm._v(_vm._s(_vm.currentDate))]), _c('span', {
    staticClass: "update"
  }, [_vm._v(" " + _vm._s(_vm.updatedAt) + "更新")])])])]), _c('section', {
    staticClass: "section_base"
  }, [_c('div', {
    staticClass: "section_base__item status_box"
  }, [_c('div', {
    staticClass: "result"
  }, [_c('p', {
    staticClass: "result__heading"
  }, [_vm._v("全生徒数")]), _c('p', {
    staticClass: "result__number"
  }, [_c('span', {
    staticClass: "num"
  }, [_vm._v(_vm._s(_vm._f("addComma")(_vm.targetSchool.student_count)))]), _c('span', {
    staticClass: "text"
  }, [_vm._v("名")])])]), _c('div', {
    staticClass: "result"
  }, [_c('p', {
    staticClass: "result__heading"
  }, [_vm._v("欠席")]), _c('p', {
    staticClass: "result__number"
  }, [_c('span', {
    staticClass: "num"
  }, [_vm._v(_vm._s(_vm.targetSchool.pointsMap ? _vm.targetSchool.pointsMap.absencePointsMap : 0))]), _c('span', {
    staticClass: "text"
  }, [_vm._v("名(" + _vm._s(((_vm.targetSchool.pointsMap ? _vm.targetSchool.pointsMap.absencePointsMap : 0) * 100 / _vm.targetSchool.student_count).toFixed(1)) + "%)")])])]), _c('div', {
    staticClass: "list"
  }, [_c('dl', {
    staticClass: "list__dl"
  }, [_c('div', {
    staticClass: "list__item"
  }, [_c('dt', {
    staticClass: "list__item__heading"
  }, [_vm._v("内)感染症")]), _c('dd', {
    staticClass: "list__item__text"
  }, [_c('span', {
    staticClass: "num"
  }, [_vm._v(" " + _vm._s(_vm._f("addComma")(_vm.targetSchool.pointsMap ? _vm.targetSchool.pointsMap.infection : 0)))]), _c('span', {
    staticClass: "text"
  }, [_vm._v("名(" + _vm._s(((_vm.targetSchool.pointsMap ? _vm.targetSchool.pointsMap.infection : 0) * 100 / _vm.targetSchool.student_count).toFixed(1)) + "%)")])])])])]), _c(_setup.Inc137)], 1), _c('div', {
    staticClass: "section_base__item condition_box"
  }, [_c(_setup.PieChart, {
    attrs: {
      "pie-chart-data": _vm.schoolPieChartData,
      "height": 280
    }
  }), _c(_setup.Inc137)], 1), _c('div', {
    staticClass: "section_base__item map_box"
  }, [_c(_setup.BarStacked, {
    attrs: {
      "school-bar-stacked-data": _vm.schoolBarStackedData
    }
  }), _c(_setup.Inc137)], 1), _c('div', {
    staticClass: "section_base__item graph_box"
  }, [_c('h3', {
    style: {
      textAlign: 'center'
    }
  }, [_vm._v("欠席者総数の変化")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.schoolBarMonth,
      expression: "schoolBarMonth"
    }],
    attrs: {
      "id": "selectBarMonth"
    },
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.schoolBarMonth = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }, _vm.schoolBarMonthChange]
    }
  }, [_c('option', {
    attrs: {
      "value": "current"
    }
  }, [_vm._v("直近31日")]), _vm._l(_vm.monthList, function (month, index) {
    return _c('option', {
      key: index,
      domProps: {
        "value": month.period
      }
    }, [_vm._v(" " + _vm._s(month.month) + "月 ")]);
  })], 2), _c(_setup.Bar, {
    attrs: {
      "child-id": "barChart2",
      "bar-data": _vm.schoolBarData,
      "active-grade": "all",
      "width": 280,
      "height": 270
    }
  }), _c(_setup.Inc137)], 1)])]), _c('div', {
    staticClass: "calendar"
  }, [_c('div', {
    staticClass: "calendar__header"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.schoolIdSelected,
      expression: "schoolIdSelected"
    }],
    staticClass: "common_form--select",
    attrs: {
      "id": "selectSchool",
      "name": "school"
    },
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.schoolIdSelected = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }, _vm.schoolChange]
    }
  }, [_c('option', {
    attrs: {
      "value": "dashboard"
    }
  }, [_vm._v("全体")]), _vm._l(_vm.schools, function (school, index) {
    return _c('option', {
      key: index,
      domProps: {
        "value": school.id
      }
    }, [_vm._v(" " + _vm._s(school.name) + " ")]);
  })], 2), _c('div', {
    staticClass: "calendar__pagination"
  }, [_c('button', {
    staticClass: "prev",
    on: {
      "click": _vm.changePrevMonth
    }
  }, [_c('svg', {
    attrs: {
      "viewBox": "0 0 24 24"
    }
  }, [_c('path', {
    attrs: {
      "fill": "currentColor",
      "d": "M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"
    }
  })])]), _c('p', {
    staticClass: "current"
  }, [_vm._v(_vm._s(_vm.calenderMonth.format("YYYY年MM月")))]), _c('button', {
    staticClass: "next",
    on: {
      "click": _vm.changeNextMonth
    }
  }, [_c('svg', {
    attrs: {
      "viewBox": "0 0 24 24"
    }
  }, [_c('path', {
    attrs: {
      "fill": "currentColor",
      "d": "M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"
    }
  })])])])]), _c(_setup.CalenderTable, {
    attrs: {
      "class-list": _vm.schoolMonthData.class,
      "total-students": _vm.targetSchool.student_count,
      "school-month-total": _vm.schoolMonthData.total,
      "calender-month": _vm.calenderMonth
    }
  })], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };