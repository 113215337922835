// 定数
// URLと認定するパターン
const urlRegExp = /^(https?|chrome):\/\/[^\s$.?#].[^\s]*$/;
export default {
  __name: 'UrlToLink',
  props: {
    text: {
      type: String,
      default: ""
    }
  },
  setup(__props) {
    const props = __props;

    // @ts-check

    const trimText = props.text.trim();
    const isUrl = urlRegExp.test(trimText);
    return {
      __sfc: true,
      urlRegExp,
      props,
      trimText,
      isUrl
    };
  }
};