export default {
  props: {
    classList: {
      type: Array,
      default: () => []
    },
    totalStudents: {
      type: Number,
      default: () => 0
    },
    schoolMonthTotal: {
      type: Object,
      default: () => {}
    },
    calenderMonth: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      monthAllDays: []
    };
  },
  watch: {
    classList: function () {
      this.monthAllDays = [];
      const lastDay = this.calenderMonth.clone().endOf("month");
      for (const d = this.calenderMonth.clone(); d < lastDay; d.add(1, "days")) {
        this.monthAllDays.push({
          class: `week_${d.day()}`,
          week: d.format("ddd"),
          key: d.format("YYYY-MM-DD")
        });
      }
    }
  },
  created() {},
  mounted() {},
  methods: {}
};