var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('button', {
    staticClass: "attachment",
    attrs: {
      "disabled": _setup.isGetS3UrlRunning
    },
    on: {
      "click": _setup.getS3SignedUrl
    }
  }, [_vm._v(" " + _vm._s(_vm.attachment.file_name) + " ")]);
};
var staticRenderFns = [];
export { render, staticRenderFns };