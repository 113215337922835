var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', [_setup.isSchoolSelect ? _c('ForumSelectSchools', {
    attrs: {
      "select-school-id-list": _setup.tempSelectIdList,
      "target": _setup.props.target
    },
    on: {
      "handleClickNext": _setup.handleClickNext,
      "toggleIsShowModal": _setup.toggleIsShowModal
    }
  }) : _c('ForumSelectDetailSchools', {
    attrs: {
      "select-school-id-list": _setup.tempSelectIdList,
      "select-grade-id-list": _vm.selectGradeIdList
    },
    on: {
      "handleClickNext": _setup.handleClickNext,
      "toggleIsShowModal": _setup.toggleIsShowModal
    }
  }), _c('div', {
    staticClass: "backdrop"
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };