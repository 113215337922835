var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('table', {
    staticClass: "calendar__table"
  }, [_c('thead', [_c('tr', [_c('th', [_vm._v("クラス名")]), _c('th', [_vm._v("生徒数")]), _vm._l(_vm.monthAllDays, function (day, i) {
    return _c('th', {
      key: i,
      class: day.class
    }, [_vm._v(" " + _vm._s(i + 1) + " "), _c('span', {
      staticClass: "week_name"
    }, [_vm._v(_vm._s(day.week))])]);
  })], 2)]), _c('tbody', [_c('tr', [_c('td', [_vm._v("全校（" + _vm._s(_vm.classList.length) + "学級）")]), _c('td', [_vm._v(_vm._s(_vm._f("addComma")(_vm.totalStudents)))]), _vm._l(_vm.monthAllDays, function (day, key) {
    return _c('td', {
      key: key,
      class: day.class
    }, [_vm._v(" " + _vm._s(_vm.schoolMonthTotal[day.key] ? _vm.schoolMonthTotal[day.key] : 0) + " ")]);
  })], 2), _vm._l(_vm.classList, function (item, j) {
    return _c('tr', {
      key: j
    }, [_c('td', [_vm._v(_vm._s(item.key))]), _c('td', [_vm._v(_vm._s(_vm._f("addComma")(item.student_count)))]), _vm._l(_vm.monthAllDays, function (day, i) {
      return _c('td', {
        key: i,
        class: day.class
      }, [_vm._v(" " + _vm._s(_vm._f("addComma")(item.value[day.key] ? item.value[day.key] : 0)) + " ")]);
    })], 2);
  })], 2)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };