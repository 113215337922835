var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "forum"
  }, [_c('div', {
    staticClass: "forum__header"
  }, [_c('h3', {
    staticClass: "forum__header__title"
  }, [_vm._v("自治体掲示板")]), _c('router-link', {
    staticClass: "forum__header__button",
    attrs: {
      "to": {
        name: 'ForumIndex'
      }
    }
  }, [_vm._v(" 一覧を見る ")])], 1), _c('div', {
    staticClass: "forum__items"
  }, _vm._l(_setup.forumList, function (forum) {
    return _c('article', {
      key: forum.id,
      staticClass: "forum__item"
    }, [_c('div', {
      staticClass: "forum__item__icons"
    }, [forum.target === 'school' ? _c('TextIcon', {
      attrs: {
        "type": "school"
      }
    }) : forum.target === 'guardian' ? _c('TextIcon', {
      attrs: {
        "type": "guardian"
      }
    }) : _vm._e(), forum.is_important ? _c('TextIcon', {
      attrs: {
        "type": "important"
      }
    }) : _vm._e(), forum.created_at !== forum.updated_at ? _c('TextIcon', {
      attrs: {
        "type": "update"
      }
    }) : _vm._e()], 1), _c('router-link', {
      staticClass: "forum__item__link",
      attrs: {
        "to": {
          name: 'ForumShow',
          params: {
            id: String(forum.id)
          }
        }
      }
    }, [_vm._v(" " + _vm._s(forum.title) + " ")]), _c('p', {
      staticClass: "forum__item__data"
    }, [_c('time', {
      staticClass: "date",
      attrs: {
        "datetime": _setup.cdate(forum.published_at).format('YYYY-MM-DD HH:mm')
      }
    }, [_vm._v(" " + _vm._s(_setup.cdate(forum.published_at).format("YYYY年MM月DD日 HH時mm分")) + " ")]), _c('span', {
      staticClass: "author"
    }, [_vm._v(_vm._s(forum.author_name))])])], 1);
  }), 0)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };