export default {
  __name: 'MessageModal',
  props: {
    okButton: {
      type: Boolean,
      default: true
    }
  },
  emits: ["close"],
  setup(__props, {
    emit
  }) {
    // @ts-check

    return {
      __sfc: true,
      emit
    };
  }
};