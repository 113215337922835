/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./ForumItem.vue?vue&type=template&id=3657b339&scoped=true&"
import script from "./ForumItem.vue?vue&type=script&setup=true&lang=js&"
export * from "./ForumItem.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./ForumItem.vue?vue&type=style&index=0&id=3657b339&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3657b339",
  null
  
)

export default component.exports