var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    attrs: {
      "id": "chart"
    }
  }, [_vm.schoolBarStackedData.class && _vm.schoolBarStackedData.class.length > 0 ? _c('apexchart', {
    attrs: {
      "type": "bar",
      "options": _vm.chartOptions,
      "height": "390",
      "series": _vm.series
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };