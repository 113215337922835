var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('main', {
    staticClass: "main"
  }, [_c('h2', {
    staticClass: "title--page"
  }, [_vm._v("メール配信一覧")]), _c('div', {
    staticClass: "search-box",
    attrs: {
      "role": "group"
    }
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _setup.filter.fiscalYear,
      expression: "filter.fiscalYear"
    }],
    staticClass: "input",
    attrs: {
      "id": "period"
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_setup.filter, "fiscalYear", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, _vm._l(_setup.fiscalYears, function (y, i) {
    return _c('option', {
      key: i,
      domProps: {
        "value": y.value
      }
    }, [_vm._v(" " + _vm._s(y.label) + " ")]);
  }), 0), _c('label', {
    staticClass: "label",
    attrs: {
      "for": "title"
    }
  }, [_vm._v("件名検索")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _setup.filter.title,
      expression: "filter.title"
    }],
    staticClass: "input",
    attrs: {
      "id": "title",
      "type": "text"
    },
    domProps: {
      "value": _setup.filter.title
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_setup.filter, "title", $event.target.value);
      }
    }
  }), _c('label', {
    staticClass: "label",
    attrs: {
      "for": "date"
    }
  }, [_vm._v("送信日")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _setup.filter.date,
      expression: "filter.date"
    }],
    staticClass: "input",
    attrs: {
      "id": "guardian",
      "type": "date",
      "max": _setup.cdate().format('YYYY-MM-DD'),
      "placeholder": "送信日を選択"
    },
    domProps: {
      "value": _setup.filter.date
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_setup.filter, "date", $event.target.value);
      }
    }
  }), _c('div', {
    staticClass: "buttons"
  }, [_c('button', {
    staticClass: "clear-button",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _setup.resetFilter.apply(null, arguments);
      }
    }
  }, [_vm._v(" クリア ")]), _c('button', {
    staticClass: "submit-button",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _setup.handleFilter.apply(null, arguments);
      }
    }
  }, [_vm._v(" 検索 ")])])]), _c('div', {
    staticClass: "body"
  }, [_c('dl', {
    staticClass: "data-list"
  }, [_c('dt', [_vm._v("配信数")]), _c('dd', [_vm._v(" " + _vm._s(_setup.forumList && _setup.forumList.filter(v => v.email_status === "sent").length) + "件 ")]), _c('dt', [_vm._v("リスト更新時刻")]), _c('dd', [_vm._v(_vm._s(_setup.forumList && _setup.forumList.last_fetched_at))])]), _c('table', {
    staticClass: "table"
  }, [_vm._m(0), _c('tbody', _vm._l(_setup.filteredForumList, function (forum) {
    return _c('tr', {
      key: forum.id
    }, [_c('td', [_vm._v(" " + _vm._s(_setup.cdate(forum.published_at).format("YYYY年MM月DD日(ddd)"))), _c('br'), _vm._v(" " + _vm._s(_setup.cdate(forum.published_at).format("HH時mm分")) + " ")]), _c('td', [_c('router-link', {
      staticClass: "button-detail",
      attrs: {
        "to": {
          name: 'ForumShow',
          params: {
            id: forum.id
          }
        }
      }
    }, [_vm._v(" " + _vm._s(forum.title) + " ")])], 1), _c('td', [_vm._v(_vm._s(_setup.emailStatus(forum.email_status)))]), _c('td', [_c('ForumSchoolCount', {
      attrs: {
        "select-school-id-list": forum.school.map(v => v.id)
      }
    }), forum.email_status === 'sent' ? _c('p', [_vm._v(" 送信数: " + _vm._s(forum.guardian_student_count) + " ")]) : _vm._e()], 1), _c('td', [_c('p', [_vm._v(_vm._s(forum.staff?.name))]), _c('p', [_vm._v(_vm._s(forum.author_name))])])]);
  }), 0)])])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('thead', [_c('tr', [_c('th', [_vm._v("送信日時")]), _c('th', [_vm._v("件名")]), _c('th', [_vm._v("状態")]), _c('th', [_vm._v("送信数")]), _c('th', [_vm._v("作成部署、氏名")])])]);
}];
export { render, staticRenderFns };