import Vue from "vue";
import { getData } from "@/services/axios";
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";
import moment from "moment";
Vue.component("VueCtkDateTimePicker", VueCtkDateTimePicker);
export default {
  name: "LogsLogin",
  components: {
    VueCtkDateTimePicker
  },
  data() {
    return {
      logs: [],
      filteredLogs: [],
      date: {
        start: null,
        end: null
      },
      keyword: "",
      publicOnly: false
    };
  },
  created() {
    this.getLogs();
  },
  methods: {
    getLogs: function () {
      getData("staff_logs?limit=500&orderby=datetime&order=desc").then(data => {
        const result = {
          f: "失敗(その他の理由)",
          fp: "失敗（パスワード誤り）",
          fu: "失敗（メールアドレス誤り）",
          s: "成功"
        };
        this.filteredLogs = this.logs = data.items.map(v => {
          // キーワード検索用に階層移動
          v.name = v.staff ? v.staff.name : "不明（削除されたスタッフの可能性があります）";
          v.result = result[v.type] ? result[v.type] : "不明";
          v.formatted = moment(v.datetime).format("MM月DD日 HH時mm分");
          return v;
        });
      });
    },
    filterData: function (e) {
      e.preventDefault();
      let logs = this.logs;
      if (this.keyword) {
        logs = logs.filter(v => Object.keys(v).some(k => String(v[k]).indexOf(this.keyword) > -1));
      }
      if (this.date.start && this.date.end) {
        const dateStart = moment(this.date.start);
        const dateEnd = moment(this.date.end);
        if (dateStart.isSame(dateEnd)) {
          logs = logs.filter(v => dateStart.isSame(moment(v.datetime).startOf("day")));
        } else {
          logs = logs.filter(v => moment(v.datetime).isBetween(dateStart, dateEnd));
        }
      }
      this.filteredLogs = logs;
    }
  }
};