var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('dialog', {
    staticClass: "modal"
  }, [_c('h3', {
    staticClass: "modal__title"
  }, [_vm._v("送信先を選択")]), _c('div', {
    staticClass: "search",
    attrs: {
      "role": "search"
    }
  }, [_c('p', {
    staticClass: "search__heading"
  }, [_vm._v("絞り込み")]), _c('label', {
    staticClass: "label",
    attrs: {
      "for": "school"
    }
  }, [_vm._v("校種")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _setup.selectSchoolType,
      expression: "selectSchoolType"
    }],
    staticClass: "select",
    attrs: {
      "id": "school",
      "name": "school"
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _setup.selectSchoolType = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("全校")]), _setup.isHaveSchoolType.el ? _c('option', {
    attrs: {
      "value": "el"
    }
  }, [_vm._v("小学校")]) : _vm._e(), _setup.isHaveSchoolType.jh ? _c('option', {
    attrs: {
      "value": "jh"
    }
  }, [_vm._v("中学校")]) : _vm._e(), _setup.isHaveSchoolType.hs ? _c('option', {
    attrs: {
      "value": "hs"
    }
  }, [_vm._v("高等学校")]) : _vm._e(), _setup.isHaveSchoolType.ss ? _c('option', {
    attrs: {
      "value": "ss"
    }
  }, [_vm._v("特別支援")]) : _vm._e()])]), _c('p', {
    staticClass: "show-schools"
  }, [_vm._v(" 候補：" + _vm._s(_setup.showSchoolList.length) + "校、 選択済み" + _vm._s(_setup.tempSelectIdList.length) + "校 ")]), _c('form', {
    staticClass: "form",
    attrs: {
      "method": "dialog"
    }
  }, [_c('div', {
    staticClass: "form__top"
  }, [_c('input', {
    staticClass: "checkbox",
    attrs: {
      "id": "all",
      "name": "allSelect",
      "type": "checkbox"
    },
    domProps: {
      "checked": _setup.isAllSelect
    },
    on: {
      "change": _setup.handleAllSelect
    }
  }), _c('label', {
    staticClass: "label",
    attrs: {
      "for": "all"
    }
  }, [_vm._v("すべて選択")]), _c('input', {
    staticClass: "checkbox",
    attrs: {
      "id": "showChecked",
      "name": "showChecked",
      "type": "checkbox"
    },
    domProps: {
      "checked": _setup.isShowChecked
    },
    on: {
      "click": function ($event) {
        return _setup.toggleIsShowChecked();
      }
    }
  }), _c('label', {
    staticClass: "label",
    attrs: {
      "for": "showChecked"
    }
  }, [_vm._v("選択済みのみ表示")])]), _c('div', {
    staticClass: "form__schools"
  }, _vm._l(_setup.showSchoolList, function (school) {
    return _c('label', {
      key: school.id,
      attrs: {
        "for": 'school' + school.id
      }
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _setup.tempSelectIdList,
        expression: "tempSelectIdList"
      }],
      staticClass: "checkbox",
      attrs: {
        "id": 'school' + school.id,
        "type": "checkbox"
      },
      domProps: {
        "value": school.id,
        "checked": Array.isArray(_setup.tempSelectIdList) ? _vm._i(_setup.tempSelectIdList, school.id) > -1 : _setup.tempSelectIdList
      },
      on: {
        "change": function ($event) {
          var $$a = _setup.tempSelectIdList,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;
          if (Array.isArray($$a)) {
            var $$v = school.id,
              $$i = _vm._i($$a, $$v);
            if ($$el.checked) {
              $$i < 0 && (_setup.tempSelectIdList = $$a.concat([$$v]));
            } else {
              $$i > -1 && (_setup.tempSelectIdList = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _setup.tempSelectIdList = $$c;
          }
        }
      }
    }), _c('span', {
      staticClass: "label"
    }, [_vm._v(_vm._s(school.short_name || school.name))])]);
  }), 0), _c('div', {
    staticClass: "form__bottom"
  }, [_c('button', {
    staticClass: "cancel",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _setup.emit('toggleIsShowModal', false);
      }
    }
  }, [_vm._v(" キャンセル ")]), _c('button', {
    staticClass: "next",
    attrs: {
      "type": "button",
      "disabled": !_setup.tempSelectIdList.length
    },
    on: {
      "click": _setup.handleClickNext
    }
  }, [_vm._v(" " + _vm._s(_vm.target === "guardian" ? "次へ" : "決定") + " ")])])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };