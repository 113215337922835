var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "inc",
    style: {
      bottom: _vm.bottomVal + 'px'
    }
  }, [_vm._v("137 inc.")]);
};
var staticRenderFns = [];
export { render, staticRenderFns };