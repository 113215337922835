// import { login } from "@/services/auth";

export default {
  name: "Login",
  data: () => ({
    isForceLogout: false
  }),
  head: {
    title: {
      inner: "ログイン"
    },
    meta: []
  },
  computed: {
    ttlJP: function () {
      let ttl = 1000 * 60 * 60;
      let ttlJP = "一定時間";
      if (ttl) {
        ttl = ttl / 1000 / 60;
        ttlJP = ttl + "分";
      }
      return ttlJP;
    }
  },
  watch: {
    "$auth.isAuthenticated": async function (v) {
      if (v) {
        this.$router.push({
          name: "Dashboard"
        });
      }
    }
  },
  created() {
    this.isForceLogout = !!localStorage.getItem("FORCE_LOGOUT");
  },
  methods: {
    handleLogin() {
      this.$auth.loginWithRedirect();
    }
  }
};