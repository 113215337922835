import { inject, computed } from 'vue';
import { allSchoolListKey, isHaveSchoolTypeKey } from '/codebuild/output/src4131501249/src/cocoo_gov/src/utils/injectionKey.js';
export default {
  __name: 'ForumSchoolCount',
  props: {
    selectSchoolIdList: {
      /** @type {PropType<number[]>} */
      type: Array,
      required: true
    }
  },
  setup(__props) {
    const props = __props;

    // @ts-check

    const allSchoolList = inject(allSchoolListKey);
    const isHaveSchoolType = inject(isHaveSchoolTypeKey);
    const schoolSelectTypeCount = computed(() => {
      const countTemp = {
        el: 0,
        jh: 0,
        hs: 0,
        ss: 0
      };
      allSchoolList.value.forEach(v => {
        if (v.type === "el") {
          if (props.selectSchoolIdList.includes(v.id)) {
            countTemp.el++;
          }
        } else if (v.type === "jh") {
          if (props.selectSchoolIdList.includes(v.id)) {
            countTemp.jh++;
          }
        } else if (v.type === "hs") {
          if (props.selectSchoolIdList.includes(v.id)) {
            countTemp.hs++;
          }
        } else if (v.type === "ss") {
          if (props.selectSchoolIdList.includes(v.id)) {
            countTemp.ss++;
          }
        }
      });
      return countTemp;
    });
    return {
      __sfc: true,
      props,
      allSchoolList,
      isHaveSchoolType,
      schoolSelectTypeCount
    };
  }
};