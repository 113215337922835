import useSWRV from "swrv";
import { getData, deleteData } from "@/services/axios";
import { cdate } from "@/utils/cdate";

import { inject, computed, watch } from 'vue';
import { isAuthenticatedKey, meKey } from '/codebuild/output/src4131501249/src/cocoo_gov/src/utils/injectionKey.js';
import { useRoute, useRouter } from 'vue-router/composables';
import { useToggle } from '@vueuse/core';
export default {
  __name: 'ForumShow',
  setup(__props) {
    // @ts-check
    const isAuthenticated = inject(isAuthenticatedKey);
    const me = inject(meKey);
    const route = useRoute();
    const router = useRouter();
    const {
      data
    } = useSWRV(() => isAuthenticated.value && `forum/${route.params.id}`, async () => {
      const result = await getData(`government_forum/${route.params.id}`);
      return result.items;
    }, {
      refreshInterval: 0,
      revalidateOnFocus: false
    });
    const selectSchoolIdList = computed(() => data.value.school.map(v => v.id));
    const [isShowDeleteConfirmModal, toggleIsShowDeleteConfirmModal] = useToggle();
    const deleteForum = async () => {
      try {
        await deleteData(`government_forum/${route.params.id}`);
        router.push({
          name: "ForumIndex"
        });
      } catch (error) {
        console.log(error);
      }
    };
    const {
      data: forumList,
      mutate
    } = useSWRV(() => isAuthenticated.value && "forum", async () => {
      const result = await getData("government_forum", {
        exclude: route.params.id
      });
      return result.items;
    }, {
      revalidateOnFocus: false
    });
    const dataForCopy = computed(() => {
      const keysToRemove = ["id", "school_ids", "school", "email_status"];
      const newObj = {
        ...data.value
      };
      keysToRemove.forEach(key => {
        delete newObj[key];
      });
      if (newObj["email_status"]) {
        newObj["email_status"] = "pending";
      }
      return newObj;
    });
    watch(() => route.params.id, () => {
      mutate();
    });
    return {
      __sfc: true,
      isAuthenticated,
      me,
      route,
      router,
      data,
      selectSchoolIdList,
      isShowDeleteConfirmModal,
      toggleIsShowDeleteConfirmModal,
      deleteForum,
      forumList,
      mutate,
      dataForCopy,
      cdate
    };
  }
};