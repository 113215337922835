import * as d3 from "d3";
export default {
  props: {
    barChartRaceData: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    barChartRaceData: function () {
      if (this.barChartRaceData.length) {
        this.barChartRace();
      }
    }
  },
  created() {},
  mounted() {},
  methods: {
    async barChartRace() {
      const n = 10;
      const k = 1;
      const barSize = 84;
      const margin = {
        top: 32,
        right: 6,
        bottom: 6,
        left: 0
      };
      const height = margin.top + barSize * n + margin.bottom;
      const width = 960;
      const duration = 2500;
      const parentDiv = d3.select(`#barChartRace`);
      d3.select("#raceSvg").remove();
      const svg = parentDiv.append("svg").attr("id", "raceSvg").attr("viewBox", [0, 0, width, height]);
      const names = new Set(this.barChartRaceData.map(d => d.name));
      const datevalues = Array.from(d3.rollup(this.barChartRaceData, ([d]) => d.value, d => d.date, d => d.name)).map(([date, data]) => [new Date(date), data]).sort(([a], [b]) => d3.ascending(a, b));
      const rank = value => {
        const data = Array.from(names, name => ({
          name,
          value: value(name)
        }));
        data.sort((a, b) => d3.descending(a.value, b.value));
        for (let i = 0; i < data.length; ++i) data[i].rank = Math.min(n, i);
        return data;
      };
      const keyframes = [];
      let ka, a, kb, b;
      for ([[ka, a], [kb, b]] of d3.pairs(datevalues)) {
        for (let i = 0; i < k; ++i) {
          const t = i / k;
          keyframes.push([new Date(ka * (1 - t) + kb * t), rank(name => (a.get(name) || 0) * (1 - t) + (b.get(name) || 0) * t)]);
        }
      }
      keyframes.push([new Date(kb), rank(name => b.get(name) || 0)]);
      const nameframes = d3.groups(keyframes.flatMap(([, data]) => data), d => d.name);
      const prev = new Map(nameframes.flatMap(([, data]) => d3.pairs(data, (a, b) => [b, a])));
      const next = new Map(nameframes.flatMap(([, data]) => d3.pairs(data)));
      const x = d3.scaleLinear([0, 1], [margin.left, width - margin.right]);
      const y = d3.scaleBand().domain(d3.range(n + 1)).rangeRound([margin.top, margin.top + barSize * (n + 1 + 0.1)]).padding(0.1);
      const formatDate = d3.timeFormat("%Y-%m-%d");
      const formatNumber = d3.format(",d");
      const textTween = (a, b) => {
        const i = d3.interpolateNumber(a, b);
        return function (t) {
          this.textContent = formatNumber(i(t));
        };
      };
      const bars = svg => {
        let bar = svg.append("g").selectAll("rect");
        return ([, data], transition) => bar = bar.data(data.slice(0, n), d => d.name).join(enter => enter.append("rect").attr("fill", "#0064B5").attr("height", y.bandwidth()).attr("x", x(0)).attr("y", d => y((prev.get(d) || d).rank)).attr("width", d => x((prev.get(d) || d).value) - x(0)), update => update, exit => exit.transition(transition).remove().attr("y", d => y((next.get(d) || d).rank)).attr("width", d => x((next.get(d) || d).value) - x(0))).call(bar => bar.transition(transition).attr("y", d => y(d.rank)).attr("width", d => x(d.value) - x(0)));
      };
      const labels = svg => {
        let label = svg.append("g").attr("text-anchor", "end").selectAll("text");
        return ([, data], transition) => label = label.data(data.slice(0, n), d => d.name).join(enter => enter.append("text").attr("transform", d => `translate(${x((prev.get(d) || d).value)},${y((prev.get(d) || d).rank)})`).attr("y", y.bandwidth()).attr("x", -85).attr("dy", "-0.25em").style("font-size", "40px").text(d => d.name).attr("fill", "#ffffff").call(text => text.append("tspan").attr("fill", "#ffffff").attr("font-weight", "normal").attr("x", -6).attr("dy", "0em").style("font-size", "40px")), update => update, exit => exit.transition(transition).remove().attr("transform", d => `translate(${x((next.get(d) || d).value)},${y((next.get(d) || d).rank)})`).call(g => g.select("tspan").tween("text", d => textTween(d.value, (next.get(d) || d).value)))).call(bar => bar.transition(transition).attr("transform", d => `translate(${x(d.value)},${y(d.rank)})`).call(g => g.select("tspan").tween("text", d => textTween((prev.get(d) || d).value, d.value))));
      };
      const ticker = svg => {
        const now = svg.append("text").style("font-size", `50px`).style("font-variant-numeric", "tabular-nums").attr("text-anchor", "end").attr("x", width - 6).attr("y", margin.top + barSize * (n - 0.45)).attr("dy", "0.32em").text(formatDate(keyframes[0][0]));
        return ([date], transition) => {
          transition.end().then(() => now.text(formatDate(date)));
        };
      };
      const axis = svg => {
        const g = svg.append("g").style("font-size", "40px").attr("transform", `translate(0,${margin.top})`);
        return ([, data], transition) => {
          const max = data[0].value;
          const axis = d3.axisTop(x).ticks(width / 160 < max ? width / 160 : max) // 6未満の値が最大の時に小数表記になるので、1ずつの区切りにする
          .tickSizeOuter(0).tickSizeInner(-barSize * (n + y.padding()));
          g.transition(transition).call(axis);
          g.select(".tick:first-of-type text").remove();
          g.selectAll(".tick:not(:first-of-type) line").attr("stroke", "white");
          g.select(".domain").remove();
        };
      };
      const updateBars = bars(svg);
      const updateAxis = axis(svg);
      const updateLabels = labels(svg);
      const updateTicker = ticker(svg);
      const wait = async ms => {
        return new Promise(resolve => {
          setTimeout(() => {
            resolve();
          }, ms);
        });
      };
      for (const keyframe of keyframes) {
        // console.log(keyframe)
        const transition = svg.transition().duration(duration).ease(d3.easeLinear);

        // Extract the top bar’s value.
        x.domain([0, keyframe[1][0].value]);
        updateAxis(keyframe, transition);
        updateBars(keyframe, transition);
        updateLabels(keyframe, transition);
        updateTicker(keyframe, transition);
        await transition.end();
        await wait(1000);
      }
    }
  }
};