import Vue from "vue";
export const getData = (target, payload) => {
  const request = (token) => {
    const p = (t) => {
      return new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get(t, {
            params: payload,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            console.log(error);
            if (error.response && error.response.status === 401) {
              Vue.prototype.$auth.logout();
            }
            reject(error);
          });
      });
    };
    if (typeof target === "string") {
      return p(target);
    } else if (Array.isArray(target)) {
      return Promise.all(target.map((t) => p(t)));
    } else {
      return new Promise((resolve, reject) => {
        reject(null);
      });
    }
  };
  return Vue.prototype.$auth.getTokenSilently().then((token) => {
    return request(token);
  });
};

export const createData = (target, payload, extraHeader = null) => {
  return Vue.prototype.$auth.getTokenSilently().then((token) => {
    const requestHeader = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .post(target, payload, Object.assign(requestHeader, extraHeader))
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
          if (error.response && error.response.status === 401) {
            Vue.prototype.$auth.logout();
          }
          reject(error);
        });
    });
  });
};

export const updateData = (target, payload, extraHeader = null) => {
  return Vue.prototype.$auth.getTokenSilently().then((token) => {
    const requestHeader = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .put(target, payload, Object.assign(requestHeader, extraHeader))
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
          if (error.response && error.response.status === 401) {
            Vue.prototype.$auth.logout();
          }

          reject(error);
        });
    });
  });
};

export const deleteData = (target) => {
  return Vue.prototype.$auth.getTokenSilently().then((token) => {
    const requestHeader = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return new Promise((resolve, reject) => {
      Vue.prototype.$http
        .delete(target, requestHeader)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
          if (error.response && error.response.status === 401) {
            Vue.prototype.$auth.logout();
          }

          reject(error);
        });
    });
  });
};
