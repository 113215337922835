import { cdate } from "@/utils/cdate";

import { computed } from 'vue';
export default {
  __name: 'ForumItem',
  props: {
    forum: {
      type: Object,
      required: true
    }
  },
  setup(__props) {
    const props = __props;

    // @ts-check

    const selectSchoolIdList = computed(() => props.forum.school.map(v => v.id));
    return {
      __sfc: true,
      props,
      selectSchoolIdList,
      cdate
    };
  }
};