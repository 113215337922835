import { dashboardTableList } from "@/lib/dashboardTableList";
export default {
  name: "TableRow",
  filters: {
    zeroFallback(val) {
      return val ? val : 0;
    }
  },
  props: {
    allSchoolsTable: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      dashboardTableList: dashboardTableList
    };
  },
  methods: {
    decimalAlignmentPercent(count, studentsCount) {
      return count ? "(" + Math.round(count / studentsCount * 1000) / 10 + "%)" : "";
    }
  }
};