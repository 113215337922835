import Vue from "vue";
import VueRouter from "vue-router";
import { authGuard } from "../auth/authGuard";
import Login from "../views/Login";
import Dashboard from "../views/Dashboard";
import School from "../views/school/Detail";
import LogsLogin from "../views/logs/Login";
import Staffs from "../views/staffs/Index";
import StaffsEdit from "../views/staffs/Edit";
import ForumIndex from "../views/forum/ForumIndex.vue";
import ForumShow from "../views/forum/ForumShow.vue";
import ForumEdit from "../views/forum/ForumEdit.vue";
import ForumMailIndex from "../views/forum/ForumMailIndex.vue";
import NotFound from "../views/NotFound";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "Login",
      component: Login,
    },
    {
      path: "/dashboard",
      name: "Dashboard",
      component: Dashboard,
      beforeEnter: authGuard,
    },
    {
      path: "/school/:id",
      name: "School",
      component: School,
      beforeEnter: authGuard,
    },
    {
      path: "/logs/login",
      name: "LogsLogin",
      component: LogsLogin,
      beforeEnter: authGuard,
    },
    {
      path: "/staffs",
      name: "Staffs",
      component: Staffs,
      beforeEnter: authGuard,
    },
    {
      path: "/staffs/edit/:id",
      name: "StaffsEdit",
      component: StaffsEdit,
      beforeEnter: authGuard,
    },
    {
      path: "/staffs/create",
      name: "StaffsCreate",
      component: StaffsEdit,
      beforeEnter: authGuard,
    },
    {
      path: "/forums",
      name: "ForumIndex",
      component: ForumIndex,
      beforeEnter: authGuard,
    },
    {
      path: "/forums/create",
      name: "ForumCreate",
      component: ForumEdit,
      beforeEnter: authGuard,
    },
    {
      path: "/forums/:id",
      name: "ForumShow",
      component: ForumShow,
      beforeEnter: authGuard,
    },
    {
      path: "/forums/edit/:id",
      name: "ForumEdit",
      component: ForumEdit,
      beforeEnter: authGuard,
    },
    {
      path: "/forum_mails",
      name: "ForumMailIndex",
      component: ForumMailIndex,
      beforeEnter: authGuard,
    },
    {
      path: "*",
      name: "NotFound",
      component: NotFound,
    },
    // {
    //   path: "/about",
    //   name: "About",
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () =>
    //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
    // },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (to.name === "ForumShow") {
      return { x: 0, y: 0 };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
