import { useVModel } from '@vueuse/core';
import { computed } from 'vue';
export default {
  __name: 'ForumSelectDetailSchoolsSchool',
  props: {
    value: {
      /** @type {PropType<number[]>} */
      type: Array,
      required: true
    },
    school: {
      /**
       * @type {PropType<{
       *   id: number;
       *   short_name: string | null;
       *   name: string;
       *   grade: { id: number; school_id: number; name: string }[];
       * }>}
       */
      type: Object,
      required: true
    },
    isShowChecked: {
      type: Boolean,
      required: true
    },
    searchWord: {
      type: String,
      required: true
    }
  },
  emits: ["input"],
  setup(__props, {
    expose,
    emit
  }) {
    const props = __props;

    // @ts-check

    const tempSelectIdList = useVModel(props, "value", emit, {
      eventName: "input"
    });

    /** 表示する学校リストを作成する */
    const showGradeList = computed(() => {
      if (props.isShowChecked && props.searchWord) {
        return props.school.grade.filter(v => tempSelectIdList.value.includes(v.id) && v.name.includes(props.searchWord));
      } else if (props.isShowChecked) {
        return props.school.grade.filter(v => tempSelectIdList.value.includes(v.id));
      } else if (props.searchWord) {
        return props.school.grade.filter(v => v.name.includes(props.searchWord));
      } else {
        return props.school.grade;
      }
    });
    const isAllGradeSelect = () => {
      return showGradeList.value.every(v => tempSelectIdList.value.includes(v.id));
    };
    const getShowGradeIdList = () => {
      return showGradeList.value.map(v => v.id);
    };
    expose({
      isAllGradeSelect,
      getShowGradeIdList
    });
    return {
      __sfc: true,
      props,
      emit,
      tempSelectIdList,
      showGradeList,
      isAllGradeSelect,
      getShowGradeIdList
    };
  }
};