var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('span', [_setup.attachment ? _c('div', {
    staticClass: "attachment"
  }, [_setup.attachment.object_key ? _c('button', {
    staticClass: "attachment-button",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _setup.getS3SignedUrl(_setup.attachment.object_key);
      }
    }
  }, [_vm._v(" 内容を確認 ")]) : _vm._e(), _c('button', {
    staticClass: "attachment-button",
    attrs: {
      "id": "file01",
      "type": "button"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _setup.unsetAttachment.apply(null, arguments);
      }
    }
  }, [_vm._v(" ファイルを差し替え ")]), _c('label', {
    staticClass: "attachment__label"
  }, [_vm._v(" " + _vm._s(_setup.attachment.file_name || _setup.attachment.name) + " ")])]) : _c('button', {
    staticClass: "reference-button",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _setup.open();
      }
    }
  }, [_vm._v(" 参照 ")]), _setup.attachmentError ? _c('p', {
    staticClass: "error"
  }, [_vm._v(" " + _vm._s(_setup.attachmentError) + " ")]) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };