import Vue from "vue";
import { getData } from "@/services/axios";

import { ref, computed, watch, provide } from 'vue';
import { isAuthenticatedKey, meKey, allSchoolListKey, isHaveSchoolTypeKey } from '/codebuild/output/src4131501249/src/cocoo_gov/src/utils/injectionKey.js';
export default {
  __name: 'App',
  setup(__props) {
    // @ts-check
    const isAuthenticated = ref(false);
    const me = ref({});
    const allSchoolList = ref([]);
    const isHaveSchoolType = computed(() => {
      return {
        el: allSchoolList.value.some(v => v.type === "el"),
        jh: allSchoolList.value.some(v => v.type === "jh"),
        hs: allSchoolList.value.some(v => v.type === "hs"),
        ss: allSchoolList.value.some(v => v.type === "ss")
      };
    });
    watch(() => Vue.prototype.$auth.isAuthenticated, async () => {
      if (Vue.prototype.$auth.isAuthenticated) {
        const data = await Promise.all([getData("me"), getData("school?with=count")]);
        me.value = data[0];
        allSchoolList.value = data[1].items;
      }
      isAuthenticated.value = Vue.prototype.$auth.isAuthenticated;
    });
    provide(isAuthenticatedKey, isAuthenticated);
    provide(meKey, me);
    provide(allSchoolListKey, allSchoolList);
    provide(isHaveSchoolTypeKey, isHaveSchoolType);
    return {
      __sfc: true,
      isAuthenticated,
      me,
      allSchoolList,
      isHaveSchoolType
    };
  }
};