import { ScalingSquaresSpinner } from "epic-spinners";
export default {
  name: "LoadingMessage",
  components: {
    ScalingSquaresSpinner
  },
  props: {
    loadingMessage: {
      type: String,
      default: ""
    }
  }
};