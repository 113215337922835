import { ref } from 'vue';
export default {
  __name: 'ForumSelect',
  props: {
    target: {
      type: String,
      required: true
    },
    selectSchoolIdList: {
      /** @type {PropType<number[]>} */
      type: Array,
      required: true
    },
    selectGradeIdList: {
      /** @type {PropType<number[]>} */
      type: Array,
      required: true
    }
  },
  emits: ["updeteSchoolIds", "updeteGradeIds", "toggleIsShowModal"],
  setup(__props, {
    emit
  }) {
    const props = __props;

    // @ts-check

    const tempSelectIdList = ref(props.selectSchoolIdList);
    const isSchoolSelect = ref(true);
    const handleClickNext = (schoolIdList, gradeIdList = []) => {
      if (props.target === "school" || !isSchoolSelect.value) {
        // 学校掲示板or学年を選んだ後
        emit("updeteSchoolIds", schoolIdList);
        if (props.target === "guardian") {
          emit("updeteGradeIds", gradeIdList);
        }
        emit("toggleIsShowModal", false);
      } else {
        tempSelectIdList.value = schoolIdList;
        isSchoolSelect.value = false;
      }
    };
    const toggleIsShowModal = input => {
      emit("toggleIsShowModal", input);
    };
    return {
      __sfc: true,
      props,
      emit,
      tempSelectIdList,
      isSchoolSelect,
      handleClickNext,
      toggleIsShowModal
    };
  }
};