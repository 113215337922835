// @ts-check
/** @type {InjectionKey<Ref<boolean>>} */
export const isAuthenticatedKey = Symbol("isAuthenticated");

/**
 * @type {InjectionKey<
 *   Ref<{
 *     id: number;
 *     name: string;
 *     allow: {
 *       admin: boolean;
 *       forum_post: boolean;
 *     };
 *     government_id: number;
 *     government_name: string;
 *     school: {
 *       id: number;
 *       name: string;
 *       type: string;
 *     }[];
 *     government_plan_allow: {
 *       forum: boolean;
 *       forum_email: boolean;
 *     };
 *     school_ids: number[];
 *   }>
 * >}
 */
export const meKey = Symbol("me");

/** @type {InjectionKey<Ref<any[]>>} */
export const allSchoolListKey = Symbol("allSchoolList");

/** @type {InjectionKey<ComputedRef<{ el: boolean; jh: boolean; hs: boolean; ss: boolean }>>} */
export const isHaveSchoolTypeKey = Symbol("isHaveSchoolType");
