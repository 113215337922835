import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueHead from "vue-head";
import VueContentPlaceholders from "vue-content-placeholders";
import IdleVue from "idle-vue";
import { Auth0Plugin } from "./auth";
import axios from "axios";
window.Vue = Vue;
Vue.router = router;
Vue.config.productionTip = false;
Vue.use(Auth0Plugin, {
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
  audience: process.env.VUE_APP_AUTH0_AUDIENCE,
  onRedirectCallback: appState => {
    router.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
  }
});
Vue.use(VueHead, {
  separator: " | "
});
Vue.use(VueContentPlaceholders);
const eventsHub = new Vue();
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  idleTime: process.env.VUE_APP_TIME_TO_AUTO_LOGOUT ? process.env.VUE_APP_TIME_TO_AUTO_LOGOUT : 1000 * 60 * 60
});
const base = axios.create({
  baseURL: process.env.VUE_APP_API_URL
});
Vue.prototype.$http = base;
Vue.filter("addComma", function (num) {
  if (Number(num)) {
    return Number(num).toLocaleString();
  } else {
    return num;
  }
});
Vue.filter("shorten", function (value, length) {
  if (!value) return "";
  if (!length) length = 10;
  if (value.length <= length) {
    return value;
  }
  return value.slice(0, length) + "...";
});
new Vue({
  router,
  render: h => h(App),
  onIdle() {
    // if (isLoggedIn()) {
    //   localStorage.setItem("FORCE_LOGOUT", "yes");
    //   logout();
    // }
  }
}).$mount("#app");