var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _setup.isUrl ? _c('span', [_c('a', {
    attrs: {
      "href": _setup.trimText,
      "target": "_blank"
    }
  }, [_vm._v(_vm._s(_setup.trimText))])]) : _c('span', [_vm._v(_vm._s(_vm.text))]);
};
var staticRenderFns = [];
export { render, staticRenderFns };