import * as d3 from "d3";
export default {
  props: {
    barData: {
      type: Array,
      default: () => []
    },
    activeGrade: {
      type: String,
      default: () => "all"
    },
    width: {
      type: Number,
      default: () => 280
    },
    height: {
      type: Number,
      default: () => 270
    },
    childId: {
      type: String,
      default: () => "barChart1"
    }
  },
  watch: {
    barData: function () {
      this.bar();
    },
    activeGrade: function () {
      this.bar();
    }
  },
  created() {},
  mounted() {},
  methods: {
    bar() {
      const parentDiv = d3.select(`#${this.childId}`);
      d3.select(`#${this.childId}Svg`).remove();
      const width = this.width;
      const height = this.height;
      const dataset = this.barData.map(v => {
        const date = new Date(v.date);
        const date_string = date.getMonth() + 1 + "/" + date.getDate();
        return {
          name: date_string,
          value: v[this.activeGrade]
        };
      });
      const xPadding = 40; // スケール表示用マージン
      const yPadding = 60;
      const svg = parentDiv.append("svg").attr("id", `${this.childId}Svg`).attr("width", width).attr("height", height);
      const xScale = d3.scaleBand().rangeRound([xPadding, width - xPadding]).padding(0.1).domain(dataset.map(function (d) {
        return d.name;
      }));
      const yScale = d3.scaleLinear().domain([0, d3.max(dataset, function (d) {
        return d.value;
      })]).range([height - yPadding, 10]);
      svg.append("g").attr("transform", "translate(" + 0 + "," + (height - yPadding) + ")").call(d3.axisBottom(xScale).tickValues(xScale.domain().filter(function (d, i) {
        return !(i % 7);
      }))).style("font-size", 12).selectAll("text");
      svg.append("g").attr("transform", "translate(" + xPadding + "," + 0 + ")").call(d3.axisLeft(yScale));
      svg.append("g").selectAll("rect").data(dataset).enter().append("rect").attr("x", function (d) {
        return xScale(d.name);
      }).attr("y", function (d) {
        return yScale(d.value);
      }).attr("width", xScale.bandwidth()).attr("height", function (d) {
        return height - yPadding - yScale(d.value);
      }).attr("fill", "#0064B5");
    }
  }
};