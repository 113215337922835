import { getData } from "@/services/axios";

import { useVModel, useFileDialog } from '@vueuse/core';
import { ref } from 'vue';
export default {
  __name: 'ForumEditAttachment',
  props: {
    value: {
      /** @type {PropType<File | any>} */
      type: [File, Object],
      default: undefined
    }
  },
  emits: ["input", "toggleIsDelete"],
  setup(__props, {
    emit
  }) {
    const props = __props;

    // @ts-check

    const attachment = useVModel(props, "value", emit, {
      eventName: "input"
    });
    const attachmentError = ref("");
    const {
      open,
      reset,
      onChange
    } = useFileDialog({
      multiple: false
    });
    onChange(files => {
      if (checkFile(files[0])) {
        attachment.value = files[0];
        attachmentError.value = "";
      } else {
        reset();
      }
    });
    const checkFile = file => {
      //拡張子はpdf, jpg, jpeg, png, gif
      const fileName = file.name;
      if (!(fileName.endsWith(".pdf") || fileName.endsWith(".jpg") || fileName.endsWith(".jpeg") || fileName.endsWith(".png") || fileName.endsWith(".gif"))) {
        attachmentError.value = "ファイルの種類が不正です。添付できるファイルの種類はpdf, jpg, jpeg, png, gifです。";
        return false;
      }
      //サイズは5MBまで
      if (file.size > 5242880) {
        attachmentError.value = "ファイルサイズが5MBを超えています。添付できるファイルのサイズは5MB以下です。";
        return false;
      }
      return true;
    };
    const unsetAttachment = () => {
      reset();
      attachment.value = null;
      emit("toggleIsDelete", true);
    };
    const getS3SignedUrl = async key => {
      //secure browserのpopup block 対策
      const newWindow = window.open();
      try {
        const data = await getData("government_forum/get_s3_signed_url?path=" + encodeURIComponent(key));
        newWindow.location = data.items.s3url;
      } catch (error) {
        console.log(error);
      }
    };
    return {
      __sfc: true,
      props,
      emit,
      attachment,
      attachmentError,
      open,
      reset,
      onChange,
      checkFile,
      unsetAttachment,
      getS3SignedUrl
    };
  }
};