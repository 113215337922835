var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "schools"
  }, [_setup.isHaveSchoolType.el ? _c('span', {
    staticClass: "schools__item"
  }, [_vm._v("小" + _vm._s(_setup.schoolSelectTypeCount.el) + "校")]) : _vm._e(), _setup.isHaveSchoolType.jh ? _c('span', {
    staticClass: "schools__item"
  }, [_vm._v("中" + _vm._s(_setup.schoolSelectTypeCount.jh) + "校")]) : _vm._e(), _setup.isHaveSchoolType.hs ? _c('span', {
    staticClass: "schools__item"
  }, [_vm._v("高" + _vm._s(_setup.schoolSelectTypeCount.hs) + "校")]) : _vm._e(), _setup.isHaveSchoolType.ss ? _c('span', {
    staticClass: "schools__item"
  }, [_vm._v("特支" + _vm._s(_setup.schoolSelectTypeCount.ss) + "校")]) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };